@font-face {
  font-family: "VWHead";
  font-weight: 800; /*Bold*/
  src: url("../assets/fonts/VWHead-Bold.otf");
  src: local("../assets/fonts/VWHead-Bold"), local("../assets/fonts/VWHead-Bold"),
    url("../assets/fonts/VWHead-Bold.otf") format("truetype");
}

@font-face {
  font-family: "VWHead";
  font-weight: 400; /*Regular*/
  src: url("../assets/fonts/VWHead-Regular.otf");
  src: local("../assets/fonts/VWHead-Regular"), local("../assets/fonts/VWHead-Regular"),
    url("../assets/fonts/VWHead-Regular.otf") format("truetype");
}

@font-face {
  font-family: "VWHead";
  font-weight: 400; /*Regular*/
  font-style: italic;
  src: url("../assets/fonts/VWHead-RegularItalic.otf");
  src: local("../assets/fonts/VWHead-RegularItalic"), local("../assets/fonts/VWHead-RegularItalic"),
    url("../assets/fonts/VWHead-RegularItalic.otf") format("truetype");
}

@font-face {
  font-family: "VWHead";
  font-weight: 300; /*Light*/
  src: url("../assets/fonts/VWHead-Light.otf");
  src: local("../assets/fonts/VWHead-Light"), local("../assets/fonts/VWHead-Light"),
    url("../assets/fonts/VWHead-Light.otf") format("truetype");
}

@font-face {
  font-family: "VWText";
  font-weight: 700; /*Bold*/
  src: url("../assets/fonts/VWText-Bold.otf");
  src: local("../assets/fonts/VWText-Bold"), local("../assets/fonts/VWText"),
    url("../assets/fonts/VWText-Bold.otf") format("truetype");
}

@font-face {
  font-family: "VWText";
  font-weight: 400; /*Regular*/
  src: url("../assets/fonts/VWText-Regular.otf");
  src: local("../assets/fonts/VWText-Regular"), local("../assets/fonts/VWText"),
    url("../assets/fonts/VWText-Regular.otf") format("truetype");
}

@import "./margins.scss";
@import "./buttons.scss";

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  overflow-x: hidden;
}

@media (min-width: 1200px) {
  body .container {
    max-width: 1350px;
  }
}

@media (max-width: 800px) {
  .row {
    flex-direction: column;
  }

  .row .form-group {
    padding: 1.5rem 0;
  }
}

.row {
  width: 100%;
  margin: 0;
}

.loading-background {
  left: 0;
  position: fixed;
  top:0;
  width: 100vw;
  height: 100vh;
  background: white;
  z-index: 100000;
}

.svg-loading {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.paste {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  z-index: 1000;
}

.box-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 1rem 0;
}

.separator-lines {
  width: 100%;
  display: flex;
  border-top: 2px solid rgba(0, 0, 0, 0.096);
  border-bottom: 2px solid rgba(0, 0, 0, 0.096);
  padding: 1.5rem 0;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}