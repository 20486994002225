.main-menu, .submenu {
    div, a {
        cursor: pointer;
        display: block;
        font-family: 'VWHead';
        font-size: 22px;
        font-weight: 300;
        margin: 0 0 16px 16px;
        text-decoration: none;
    }
}

.main-menu {
    padding: 40px 48px;

    div, a {
        color: white;
    }
}

.submenu {
    overflow-y: auto;    
    div, a {
        color: black;
    }
}