.btn-primary {
    background-color: #00b0f0;
    border-color: #00b0f0;
}
.btn-secondary {
    background-color: #001e50;
    border-color: #001e50;
}
.btn-outline-primary {
    background-color: transparent;
    border-color: #00b0f0;
}
.btn-outline-secondary {
    background-color: transparent;
    border-color: #001e50;
}